@import '../../../styles/variables.less';
.gx-main-content-wrapper {
  .app-name-header {
    .app-name-header-layout {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      width: 80%;
      @media (max-width: 820px) {
        width: 90%;
      }
    }
    .app-name-header-left {
      width: 33.33%;
      display: flex;
      align-items: center;
    }
    .app-name-header-middle {
      width: 33.33%;
      min-width: 330px;
    }
    .app-name-header-right {
      width: 33.33%;
      display: flex;
      justify-content: flex-end;
      .ant-dropdown-menu-item,
      .ant-dropdown-menu-submenu-title {
        padding: 4px;
        border-radius: 5px;
      }
    }
  }
  .ant-layout-header {
    padding: 0;
  }
  .avatar-width {
    min-width: 28px;
    margin-right: 12px;
  }
  .header-app-name {
    font-weight: 500;
    line-height: 24px;
    overflow: hidden;
    width: 67%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.delete-app-name {
  font-size: 16px;
  font-weight: 500;
}

.app-card {
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: @white-color;
  min-height: calc(100vh-112px);
  .ant-card {
    width: 600px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }
  .ant-card-head {
    padding: 0px 16px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
  }
  .ant-form-item-label > label {
    color: @text-color;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
}

.app-card-content {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  .ant-card-body {
    padding: 24px 16px 16px;
  }
  .ant-divider-horizontal {
    margin: 24px -16px 16px;
    width: auto;
  }
  .ant-input {
    padding: 8px;
  }
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
  .upload-button {
    font-weight: 500;
  }
}

.profile-card-title {
  font-weight: 500;
  line-height: 26px;
}

.app-upload {
  display: flex;
  align-items: center;
  .ant-upload-picture-card-wrapper {
    width: 60px;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 60px;
    height: 60px;
    margin: 0;
    border-radius: 50%;
    background-color: @white-color;
    border: none;
  }

  .ant-upload-list-picture-card-container {
    max-width: 60px;
    max-height: 60px;
    background-color: @white-color;
    border: none;
  }
  .ant-upload.ant-upload-select-picture-card:hover {
    border: none;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    border-radius: 50%;
    padding: 0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    border-radius: 32px;
  }
  .ant-btn-icon-only.ant-btn-sm {
    width: 25px;
    padding: 0px;
    margin: 0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }
}

.upload-button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.upload-button {
  width: 16%;
}
