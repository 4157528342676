@import '../../styles/variables.less';
.create-app-div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 56px);
  align-items: center;

  .create-app-card {
    margin: 130px 0 20px 0;
    height: 200px;
    width: 421px;
    border-radius: 8px;

    .create-card-heading {
      font-weight: 500;
      line-height: 36px;
      color: @heading-color;
    }

    .card-content {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 24px;

      .ant-upload {
        .h-16 {
          height: 34px;
        }
      }
      h1 {
        font-weight: 500;
      }
      .card-text {
        padding-left: 8px;
      }
    }
    .card-form {
      margin: 16px 0px;
      .app-name-input {
        width: 100%;
        margin-right: 16px;
      }
    }
  }

  .mob {
    bottom: 0;
  }
}
