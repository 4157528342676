@import '../../../styles/variables.less';
.workspace-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 438px;
  height: calc(100vh - 193px);
  .step-content {
    text-align: left;
    border: 1px solid @secondary-color;
    .workspace-text {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      color: @heading-color;
    }

    .image-text {
      line-height: 24px;
      font-size: 12px;
      margin-left: 5px;
      display: flex;
      align-items: center;
    }
    .next-btn {
      width: 86px;
      margin-left: 24px;
    }

    .workspace-input {
      width: 280px;
    }

    .apk-uploader {
      cursor: pointer;
      img {
        width: 30px;
        height: 30px;
      }
    }
  }

  .step-content-form {
    padding: 8px 0px;
  }
  .team-box {
    display: flex;
    justify-content: center;
  }
}
