@import '../../styles/variables.less';

.update-content-row {
  width: 100%;
  margin-right: 36px;
  .info-icon {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.update-content-preview {
  position: relative;
}
