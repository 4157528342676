// Login page styles

.layout {
  min-height: 100vh;
  & .login-row {
    min-height: calc(100vh - 63px);
    padding: 0 10%;
    .logo-section {
      display: flex;
      justify-content: center;
    }
  }
}
