@import '../../styles/variables.less';

.link {
  color: @heading-color;
}
.s-content {
  font-weight: 400;
  line-height: 18px;
  color: @heading-color;
}

.static-content {
  margin-left: 4px;
  font-weight: 400;
  line-height: 18px;
  color: @text-color;
}

.heading {
  font-weight: 400;
  line-height: 24px;
  color: @heading-color;
}

.update-card-content {
  width: 100%;
  margin-top: 16px;
  border: 1px solid @secondary-color;
  border-radius: 8px;
  .update-panel {
    display: flex;
    align-items: center;
    img {
      object-fit: cover;
    }
  }
  .content {
    font-size: 16px;
    line-height: 24px;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding-right: 16px;
  }
  .icon-style {
    color: #585e75;
    font-size: 18px;
  }
  .ant-checkbox-inner {
    border-radius: 4px;
  }
  .ant-form-item-label > label {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: @text-color;
  }
  .force-update-text {
    color: @heading-color;
  }
  .anticon-info-circle {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.android-collapse {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
}

.update-button {
  margin-top: 24px;
  justify-content: flex-end;
}

.col-link {
  width: 100%;
}
.col-build {
  min-width: 49%;
}
.row-build {
  display: flex;
  justify-content: space-between;
}
