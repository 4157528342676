@import '../../../styles/variables.less';

.gx-main-content-wrapper {
  .no-apps-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    padding: 32px 32px 0;

    .no-apps-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 24px 0 8px 0;

      h1 {
        font-size: 24px;
        font-weight: 500;
        color: @text-color;
      }

      .no-apps-release-content {
        p {
          font-size: 16px;
          color: @text-color;
        }
      }

      .no-apps-btn {
        margin: 24px 0 8px 0;
      }
    }
    .create-app-btn {
      width: 108px;
      height: 38px;
      font-weight: 500;
    }
  }
}
