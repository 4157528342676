@import '../../../styles/variables.less';

.no-release {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  margin-top: 155px;
}

.no-release-content {
  position: absolute;
  bottom: 8%;
  width: 394px;
}

.row-app {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: @text-color;
}

.row-create {
  font-weight: 400;
  line-height: 24px;
  color: @text-color;
}

.button-release {
  margin-top: 24px;
}
