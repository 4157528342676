@import '../../styles/variables.less';

.maintenance-preview-mobile {
  width: 240px;
  height: 490px;
  border: 12px solid #121515;
  border-radius: 40px;
  background-color: @background-color;
}

.main-image-preview {
  position: absolute;
  top: 35%;
  right: 41%;
}

.maintenance-preview {
  position: absolute;
  width: 218px;
  height: 469px;
  top: 2%;
  left: 11px;
  border-radius: 29px;
  padding: 12px;
  overflow: hidden;
}

.maintenance-preview-content {
  position: absolute;
  top: 50%;
  padding-right: 12px;
  transform: translateY(-50%);
}

.image-preview {
  height: 48px;
  top: 30%;
  border-radius: 8px;
}

.maintenance-text {
  margin-top: 16px;
  font-weight: 500;
  line-height: 20px;
  word-break: break-all;
}

.main-text {
  margin-top: 8px;
  font-weight: 400;
  line-height: 18px;
  opacity: 0.8;
  word-break: break-all;
}

.image-preview-text {
  display: flex;
  text-align: center;
  position: absolute;
  top: 49%;
  left: 17%;
  right: 17%;
}

.preview-text {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 44%;
  left: 16%;
  line-height: 22px;
  font-weight: 400;
  color: @heading-color;
}
