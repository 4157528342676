@import '../../styles/variables.less';

.profile-card {
  display: flex;
  flex-direction: column;
  .ant-card {
    width: 600px;
  }
  .ant-card-head {
    padding: 0px 16px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
  }
  .ant-form-item-label > label {
    color: @text-color;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
  .ant-input[disabled] {
    color: @text-color;
    background-color: @background-color;
  }
  .profile-heading {
    line-height: 22px;
    margin-left: 8px;
  }
}

.profile-upload {
  display: flex;
  align-items: center;
  .ant-upload-picture-card-wrapper {
    width: auto;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: @white-color;
    border: none;
  }

  .ant-upload-list-picture-card-container {
    max-width: 64px;
    max-height: 64px;
    background-color: @white-color;
    border: none;
  }
  .ant-upload.ant-upload-select-picture-card:hover {
    border: none;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    border-radius: 50%;
    padding: 0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    border-radius: 32px;
  }
  .ant-btn-icon-only.ant-btn-sm {
    width: 25px;
    padding: 0px;
    margin: 0;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }
}

.card-content {
  display: flex;
  justify-content: center;
  margin-top: 32px;

  .ant-card-body {
    padding: 24px 16px 16px;
  }

  .ant-divider-horizontal {
    margin: 24px -16px 16px;
    width: auto;
  }

  .ant-input {
    padding: 8px;
  }
  .save-button {
    font-weight: 500;
    width: 96px;
    height: 38px;
  }
}

.card-title {
  font-weight: 500;
  line-height: 26px;
}

.icon-image {
  border-radius: 100px;
  border: 1px solid @secondary-color;
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.save-button {
  width: 16%;
}
