@import '../../styles/variables.less';
.gx-main-content-wrapper {
  &.public {
    display: flex;
    justify-content: center;
  }
  .apps-content.public-page.ios {
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    .main-content {
      width: 700px;
    }
  }
  .apps-content.public-page {
    width: 100%;
    .ios-udid {
      text-align: center;
      .udid-error {
        padding: 16px;
        h1 {
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 32px;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          &.or {
            font-weight: 600;
          }
        }
        @media screen and (max-width: 767px) {
          h1 {
            font-size: 24px;
          }
        }
        canvas {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          margin: auto;
        }
      }
      .udid-start {
        padding: 16px;
        h1 {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 20px;
          margin-top: 50px;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          &.or {
            font-weight: 600;
          }
        }
        .ant-btn-primary {
          margin-top: 16px;
        }
        @media screen and (max-width: 767px) {
          h1 {
            font-size: 18px;
          }
        }
      }
      .udid-success {
        padding: 16px;
        .udid-card {
          max-width: 500px;
          margin-top: 20px;
          .ant-card-head-title {
            font-weight: 700;
          }
          @media screen and (max-width: 575px) {
            max-width: 350px;
          }
        }
      }
      .ant-card-body {
        p {
          font-size: 16px;
          color: #262626;
          @media screen and (max-width: 575px) {
            font-size: 14px;
          }
        }
      }
      .udid-image {
        position: relative;
        svg {
          @media screen and (max-width: 767px) {
            height: 450px;
            width: 100%;
          }
          @media screen and (max-width: 575px) {
            height: 250px;
            width: 100%;
          }
        }
        .udid-mobile {
          height: 420px;
          width: 100%;
          @media screen and (max-width: 350px) {
            height: 380px;
            width: 100%;
          }
        }
      }
    }
    .main-content {
      margin: auto;
      width: 980px;
      overflow: hidden;
      .login-heading-text {
        font-weight: 500;
        color: @heading-color;
        line-height: 36px;
      }
      .gx-login-form {
        .password-input {
          max-width: 371px;
          background-color: @white-color;
        }
      }
      .empty-no-link-image {
        height: calc(100vh - 32px);
      }

      .empty-image {
        width: 386px;
        @media (max-width: 575px) {
          width: 255px;
        }
      }
      .ant-card-head {
        padding: 0 16px;
      }
      .ant-card-body {
        padding: 16px;
      }
      .ant-tag {
        border-radius: 4px;
        padding: 4px 12px;
        border: none;
        background-color: @background-color;
        width: 150px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (max-width: 991px) {
          width: 130px;
        }
        @media (max-width: 575px) {
          width: 100px;
          padding: 4px 6px;
        }
      }
      .ant-tag:last-child {
        margin-right: 0px;
      }
      .ant-card-actions {
        padding: 0 16px;
        span {
          cursor: unset;
          a {
            cursor: unset;
          }
        }
      }
      .ant-descriptions-row > th {
        padding-bottom: 2px;
      }
      .extra-text {
        line-height: 24px;
        font-weight: 500;
        color: @heading-color;
      }
      .app-name-text {
        line-height: 32px;
        font-weight: 500;
        color: @heading-color;
      }
      .app-detail-qr {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 8px;
        background-color: @background-color;
        padding: 24px;

        .public-content {
          color: @heading-color;
          margin-bottom: 16px;
        }

        .qr-content {
          padding: 16px;
          padding-bottom: 0px;
          background-color: @white-color;
          border-radius: 8px;
          .qr-content-row {
            margin-top: 10px;
          }
        }
      }

      .release-note-content {
        height: 100px;
      }

      .release-item {
        overflow-wrap: break-word;
        .ant-card-body {
          overflow: auto;
          max-height: 300px;
        }
      }

      .release-item-detail {
        overflow-wrap: break-word;
        .ant-card-body {
          overflow: auto;
          max-height: 300px;
        }
      }

      .permission-card {
        .ant-card-body {
          padding-right: 0px;
        }
        .avatar-count {
          color: @black-color;
          background-color: @background-color;
        }
      }

      .permission-content {
        display: flex;
        flex-direction: column;
        height: 256px;
        overflow-y: auto;
      }

      .single-detail {
        padding: 16px 0;
      }

      .permission-content::-webkit-scrollbar-thumb {
        background-color: @secondary-color;
        border-radius: 20px;
        border: 3px solid @white-color;
      }

      .permission-content::-webkit-scrollbar {
        width: 8px;
      }

      .permission-content::-webkit-scrollbar-track {
        background: @white-color;
      }

      .app-detail-container {
        overflow: auto;
        padding: 16px;
        .ant-card-actions > li {
          text-align: left;
        }
        .count {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          font-size: 12px;
          background: @background-color;
          font-weight: 500;
          border: none;
        }
        .desktop-download {
          height: 38px;
          width: 179px;
          font-weight: 500;
        }
        .mobile-download {
          height: 48px;
          width: 199px;
          font-weight: 500;
          padding: 11px 16px;
          span {
            line-height: 16px;
          }
        }
        @media (max-width: 575px) {
          overflow: auto;
          margin: 16px;
          column-count: 1;
          padding: 0;
          .release-item {
            display: none;
            visibility: hidden;
          }
        }
      }

      .release-item {
        visibility: hidden;
      }

      .hidden-area {
        visibility: hidden;
      }

      .item {
        width: 100%;
        break-inside: avoid;
        overflow: hidden;
        padding-bottom: 24px;
        .ant-card {
          overflow: hidden;
        }
      }

      .build-details-content {
        .ant-descriptions-item-container .ant-descriptions-item-content {
          color: @heading-color;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        }
        .ant-descriptions-item-container .ant-descriptions-item-label {
          color: @text-color;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .left-google-ad {
        position: absolute;
        padding: 20px;
        left: 0;
        top: 56px;
        height: 100%;
        width: calc((100% - 980px) / 2);
      }
      .right-google-ad {
        position: absolute;
        padding: 20px;
        right: 0;
        top: 56px;
        height: 100%;
        width: calc((100% - 980px) / 2);
      }
    }
  }
}
.google-ad-banner {
  max-width: 980px;
  width: 100%;
  margin: auto;
  .adsbygoogle {
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .gx-main-content-wrapper {
    .apps-content.public-page {
      .main-content {
        width: 100%;
        padding: 0;
        margin: auto;
        .item {
          width: 100%;
          .ant-card-head {
            padding: 16px;
          }
          .ant-card-head-title {
            padding: 0px;
          }
          .ant-card-extra {
            padding: 0px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1004px) {
  .gx-main-content-wrapper {
    .apps-content.public-page {
      width: 100%;
      .main-content {
        width: 100%;
        .item {
          min-width: 360px;
          width: unset;
        }
      }
    }
  }
}
