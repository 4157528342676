@import '../../styles/variables.less';

.release-feedback-modal {
  .ant-modal-header {
    .ant-modal-title {
      .feedback-title {
        font-size: 18px;
        font-weight: 600;
        color: @heading-color;
      }
      .device-type {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .feedback-content {
    width: 95%;
    margin: auto;
    .feedbacks {
      width: 100%;
      margin-bottom: 20px;
      .feedback-header {
        font-size: 18px;
        font-weight: 500;
      }
      .feedback-search-input {
        height: 36px;
        width: 200px;
        margin-right: 32px;
        input {
          min-height: 18px;
          padding: 1px;
        }
      }
      .filter {
        display: flex;
        align-items: center;
        gap: 8px;
        .feedback-filter {
          width: 120px;
        }
      }
      .feedback-card-list {
        height: calc(100vh - 300px);
        overflow: auto;
        overflow-x: hidden;
        padding: 4px 12px;
        .feedback-card-row {
          height: 100%;
          .feedback-card {
            background-color: @background-color;
            .time {
              font-size: 12px;
              color: @text-color;
              margin-left: 16px;
            }
            .ant-card-meta-description {
              color: @text-color;
              .app-list-info {
                .media {
                  display: flex;
                  gap: 12px;
                  margin-top: 16px;
                }
                span {
                  font-size: 16px;
                  font-weight: 400;
                  color: @heading-color;
                }
                .ant-image-mask {
                  border-radius: 8px;
                  .anticon-eye {
                    color: @white-color;
                  }
                }
                .video-placeholder {
                  width: inherit;
                  max-width: 100%;
                  width: 96px;
                  height: 96px;
                  border-radius: 8px;
                  position: absolute;
                  top: 0;
                  object-fit: none;
                }
                .video-thumbnail {
                  position: relative;
                  width: 96px;
                  height: 96px;
                  border-radius: 8px;
                  overflow: hidden;
                  cursor: pointer;

                  .react-thumbnail-generator {
                    video {
                      display: none;
                    }
                    canvas {
                      display: none;
                    }
                  }
                  .ant-icon-play-circle {
                    position: absolute;
                    color: @white-color;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    right: 0;
                    svg {
                      height: 20px;
                      width: 20px;
                    }
                  }
                }
              }
            }
            .resolved-by {
              .ant-checkbox-wrapper {
                margin-bottom: 10px;
                color: @black-color;
                span {
                  font-size: 16px;
                }
                .ant-checkbox-disabled + span {
                  color: rgba(0, 0, 0, 0.5);
                }
              }
              span.resolve-by {
                color: rgba(0, 0, 0, 0.5);
                font-size: 16px;
              }
            }
            .ant-dropdown-menu {
              padding: 8px;
              .ellipsis-menu-divider {
                margin: 4px 8px;
              }
            }
            .resolved-by {
              p {
                margin-bottom: 10px;
              }
            }
          }
          .feedback-infinite-loading {
            width: 100%;
          }
          .no-feedback {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          }

          .no-release {
            margin: auto;
            justify-content: center;
            padding-top: 75px;
          }
        }
      }
      .ant-checkbox-inner {
        border-radius: 4px;
      }
    }
  }
}

/* need to overwrite ant design class due to rootClassName prop is not supported in current antd version */
.ant-image-preview-img-wrapper {
  .ant-image-preview-img {
    max-height: 84%;
  }
}

.video-player-modal {
  .video-adjust {
    max-width: 100%;
    max-height: 77vh;
  }
}
.ant-row {
  .resolver {
    display: flex;
    align-items: center;
    margin-right: 24;
  }
  .feedback-model {
    padding: 0px 16px 16px 48px;
  }
  .resolved-title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 18px;
  }
  .resolve-comment {
    padding-top: 16px;
    font-size: 16px;
    font-weight: 400;
    color: @heading-color;
  }
  .resolver-title {
    font-weight: 400;
  }
}
