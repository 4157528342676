@import '../../styles/variables.less';

.maintenance-card-content {
  width: 100%;
  margin-top: 16px;
  border: 1px solid @secondary-color;
  border-radius: 8px;

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding-right: 16px;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
    margin: 4px 16px 16px 16px;
    border-radius: 8px;
    border: 1px solid @secondary-color;
  }

  .ant-form-item-label > label {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: @text-color;
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 4px;
  }
}
.s-content {
  font-weight: 400;
  line-height: 18px;
}
.fix-content {
  font-weight: 400;
  line-height: 24px;
}

.panel-row {
  display: flex;
  justify-content: space-between;
}

.main-collapse {
  width: 82%;
}
.main-panel {
  color: @gray-color;
  .anticon svg {
    width: 16px;
    height: 16px;
  }
}

.main-card-content {
  margin-top: 16px;
  width: 90%;
}

.main-card-button {
  margin-top: 24px;
  justify-content: flex-end;
}

.upload-content {
  border-radius: 8px;
  border: 1px dashed @secondary-color;
  padding: 8px;
  width: 40%;
  @media (max-width: @screen-lg-min) {
    width: 100%;
  }
  img {
    border-radius: 8px;
    margin-right: 8px;
  }
}
.upload-image {
  width: 60px;
  height: 60px;
}
.maintain-col {
  width: 100%;
  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper {
      padding: 0px 11px;
    }
  }
}

.maintain-row {
  display: flex;
  justify-content: space-between;
}

.maintain-col-content {
  min-width: 48%;
}

.maintenance-heading {
  font-weight: 400;
  color: @heading-color;
}

.maintenance-text-upload {
  font-weight: 400;
  line-height: 22px;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
