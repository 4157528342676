@import '../../../styles/variables.less';

.teams {
  width: 80%;
  margin: auto;
  padding: 32px 0 0;
  height: calc(100vh - 56px);

  .plus-color {
    color: @text-color;
  }

  .create-group-icon {
    margin-top: 0px;
  }

  .avatar-add-icon {
    background-color: @background-color;
    cursor: pointer;
  }

  .member-list-row {
    :hover {
      background-color: @background-color;
    }
    .ant-avatar-string:hover {
      background-color: transparent;
    }
    .ant-avatar-string {
      span:hover {
        background-color: transparent;
      }
    }
    .ant-avatar-group {
      padding-right: 2px;
    }
    .member-lists {
      padding: 0 16px;
    }
  }

  .group-list-row {
    cursor: pointer;
    :hover {
      background-color: @background-color;
    }
    .ant-avatar-string:hover {
      background-color: transparent;
    }
    .ant-avatar-string {
      span:hover {
        background-color: transparent;
      }
    }
  }
  .member-lists {
    cursor: pointer;
    margin-bottom: 0;
    height: 70px;
    display: flex;
    align-items: center;
  }
  .group-lists {
    padding: 0 16px;
    cursor: pointer;
    margin-bottom: 0;
    height: 70px;
    display: flex;
    align-items: center;
    .ant-avatar-group {
      padding-right: 2px;
    }
  }
  .members-list-wrap {
    .ant-card-head {
      display: flex;
      .add-new-members {
        padding: 24px 16px 24px 16px;
      }
      .ant-card-head-title {
        margin-right: 12px;
      }
      .count {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        font-size: 12px;
        background: @background-color;
        font-weight: 500;
        border: none;
      }
    }

    .card-body {
      overflow: hidden;
      .ant-card-body {
        padding: 0px;
      }
      .search-member {
        border-bottom: 1px solid @gray-color;
        padding: 16px;
        .ant-input-affix-wrapper {
          height: 38px;
          .ant-input-prefix {
            margin-right: 10px;
          }
          .ant-input {
            min-height: 22px;
          }
        }
        .add-member {
          cursor: pointer;
          margin-left: 8px;
          .ant-avatar-image {
            background-color: @gray-color;
            color: @gray-color;
            img {
              width: 50%;
              height: 50%;
              vertical-align: middle;
              display: inline-block;
            }
          }
        }
      }
      .member-list-title {
        padding: 16px 16px 8px;
      }
      .no-data-found {
        padding: 16px;
        height: calc(100vh - 300px);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .member-list {
        max-height: calc(100vh - 300px);
        overflow: auto;
        .ant-loader {
          display: flex;
          justify-content: center;
          padding-bottom: 30px;
        }
        .avatar-background {
          background-color: @gray-color;
        }
        .avatar-add-icon {
          img {
            width: 65%;
            height: 50%;
            vertical-align: middle;
            display: inline-block;
          }
        }
        @media screen and (max-width: 1200px) {
          max-height: 300px;
        }
      }
    }
  }
  .member-tag {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    .user-email {
      width: 95%;
      line-height: 16px;
      word-break: break-word;
    }
  }
  .member-tag-role {
    background-color: @white-color;
    color: @text-color;
    :hover {
      background-color: transparent;
    }
    margin-bottom: 15px;
    margin-left: 45px;
  }
  .avatar-image-icon {
    margin-right: 3px;
    margin-bottom: 3px;
  }
  .group-list-wrap {
    .ant-input-affix-wrapper {
      height: 38px;
      .ant-input-prefix {
        margin-right: 10px;
      }
      .ant-input {
        min-height: 22px;
      }
    }
    .ant-card-head {
      display: flex;
      .ant-card-head-title {
        margin-right: 12px;
      }
      .count {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        font-size: 12px;
        background: @background-color;
        font-weight: 500;
        border: none;
      }
    }
    .card-body {
      .ant-card-body {
        padding: 0px;
      }
      .search-group {
        border-bottom: 1px solid @gray-color;
        padding: 16px;
        .ant-input-affix-wrapper {
          .ant-input-prefix {
            margin-right: 10px;
          }
        }
        .add-group {
          cursor: pointer;
          margin-left: 8px;
          .ant-avatar-image {
            background-color: @gray-color;
            color: @gray-color;
            img {
              width: 50%;
              height: 50%;
              vertical-align: middle;
              display: inline-block;
            }
          }
        }
      }
      .group-list-title {
        padding: 16px;
      }
      .group-add-icon {
        background-color: @background-color;
        cursor: pointer;
      }
      .group-list {
        max-height: calc(100vh - 300px);
        overflow: auto;
        .ant-loader {
          display: flex;
          justify-content: center;
        }
        @media screen and (max-width: 1200px) {
          max-height: 300px;
        }
      }
    }
  }
}
.delete-member-modal {
  .delete-member {
    padding: 16px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
  .ant-modal-footer {
    border-top: none;
  }
}

.tags {
  display: flex;
}
.teams .members-list-wrap .card-body .search-member {
  border-bottom: 1px solid @border-settings-color;
}
.teams .group-list-wrap .card-body .search-group {
  border-bottom: 1px solid @border-settings-color;
}
.member-header-edit-group {
  display: flex;
  align-items: center;
  .span {
    margin-right: 10px;
  }
}
.group-modal {
  .group-member-lists {
    padding: 0 16px;
    cursor: pointer;
    margin-bottom: 0;
    height: 70px;
    display: flex;
    align-items: center;
  }
  .count {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 12px;
    background: @background-color;
    font-weight: 500;
    border: none;
  }
  .remove-member-text {
    cursor: pointer;
    font-size: 10px;
    line-height: 18px;
    margin-right: 10px;
  }
  .add-new-groups {
    padding: 24px 16px 0px;
  }
  .add-member-button-form-item {
    margin-bottom: 0;
    .add-member-btn {
      margin-left: 16px;
      width: 60px;
      font-weight: 500;
    }
  }
  .add-member-form-item {
    margin-bottom: 0;
  }
  .edit-group-header {
    padding-right: 0;
    padding-left: 0;
  }
  .ant-modal-content {
    .group-members-list {
      padding: 16px 0 0;
      min-height: 100px;
      max-height: 350px;
      overflow: auto;
      .group-shared-apps {
        margin: 20px;
      }
    }
  }
  .ant-modal-footer {
    .is-edit-group-footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .ant-form-item {
      margin: 0px;
    }
  }
  .delete-icon {
    width: 18px;
    margin-right: 8px;
  }
}
.edit-group {
  padding: 0 16px 16px;
  border-bottom: 1px solid @secondary-color;
  .ant-input-affix-wrapper {
    height: 38px;
    .ant-input-prefix {
      margin-right: 10px;
    }
    .ant-input {
      min-height: 22px;
    }
  }
}
.edit-group-tabs {
  .ant-tabs-nav-wrap {
    margin-left: 16px;
  }
  .ant-tabs-tab {
    padding: 16px 0;
  }
  .ant-tabs-nav-wrap {
    height: 54px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @heading-color;
  }
  .ant-tabs-tab:hover {
    color: @heading-color;
  }
  .group-members-list {
    .member-tag {
      display: flex;
      flex-direction: column;
    }
  }
}
.member-list-hover {
  :hover {
    background-color: @background-color;
  }
  .ant-avatar-string:hover {
    background-color: transparent;
  }
  .ant-avatar-string {
    span:hover {
      background-color: transparent;
    }
  }

  .list {
    padding: 24px 16px 16px;
    .member-lists {
      height: 38px;
    }
  }
  .edit-group-list-avatar-icon {
    color: white;
  }
  .members-tag {
    margin-bottom: 4px;
  }
  .member-tag-role {
    background-color: @white-color;
    color: @text-color;
    :hover {
      background-color: transparent;
    }
  }
  .remove-member-column {
    display: flex;
    justify-content: flex-end;
  }
}

.shared-app-row-div.member-tab-div {
  height: 256px;
}

.shared-app-row-div {
  overflow: auto;
  .ant-empty-image {
    height: inherit;
  }

  .shared-app-row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .shared-app-avatar {
      height: 40px;
      width: 40px;
    }
    .shared-app-name {
      font-size: 14px;
      color: @heading-color;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

.shared-app-row-div::-webkit-scrollbar-track {
  background: @white-color;
}

.shared-app-row-div::-webkit-scrollbar-thumb {
  background-color: @secondary-color;
  border-radius: 20px;
  border: 3px solid @white-color;
}

.member-list::-webkit-scrollbar-track {
  background: @white-color;
}

.member-list::-webkit-scrollbar-thumb {
  background-color: @secondary-color;
  border-radius: 20px;
  border: 3px solid @white-color;
}

.group-list::-webkit-scrollbar-track {
  background: @white-color;
}

.group-list::-webkit-scrollbar-thumb {
  background-color: @secondary-color;
  border-radius: 20px;
  border: 3px solid @white-color;
}

.delete-group-text {
  margin-bottom: 0;
  color: @error-color;
  line-height: 22px;
}
.add-member-modal {
  .ant-empty-image {
    height: inherit;
  }
  .add-padding {
    padding: 24px 16px 0px;
  }
  .edit-padding {
    padding: 0 16px;
  }
  .ant-select-multiple .ant-select-selection-item {
    background-color: @white-color;
    border-radius: 4px;
  }
  .member-tab {
    .ant-tabs-tab {
      padding: 16px 0;
    }
    .ant-tabs-nav-wrap {
      height: 54px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: @heading-color;
    }
    .ant-tabs-tab:hover {
      color: @heading-color;
    }
  }
  .invite-button {
    font-weight: 500;
    width: 96px;
    height: 38px;
  }
  .is-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .delete-user {
    margin-bottom: 0;
    color: @error-color;
    line-height: 22px;
  }
  .delete-icon {
    width: 18px;
    margin-right: 8px;
  }
  .is-edit-child-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .count {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    font-size: 12px;
    background: @background-color;
    font-weight: 500;
    border: none;
  }
  .add-member-button {
    margin-left: 0;
  }
  .add-member {
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 2px;
  }
  .form-item {
    margin-bottom: 0;
  }
  .ant-space-item {
    width: 100%;
  }
  .space-users {
    margin-top: 0;
    width: 100%;
  }
  .invitation {
    margin: 4px 0px 16px 0px;
    color: #007aff;
    cursor: pointer;
    text-decoration: underline;
  }
}

.no-group-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 32px 32px;
  height: 410px;

  .no-group-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px 0 8px 0;

    h1 {
      font-size: 24px;
      font-weight: 500;
      color: @text-color;
    }

    .no-group-release-content {
      p {
        font-size: 16px;
        color: @text-color;
      }
    }

    .no-group-btn {
      margin: 24px 0 8px 0;
    }
  }
}

.ant-avatar-group-popover {
  .ant-popover-inner-content {
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    span {
      margin: 8px 0;
    }
  }
}

.member-data-select {
  .rc-virtual-list-holder-inner {
    .ant-select-item {
      padding: 0;
    }
    .row-item-height {
      height: 70px;
      display: flex;
      align-items: center;
      padding: 0 16px;
    }
  }
  .group-modal-member-role-tag {
    margin-right: 0;
  }
  .ant-select-item-option-state {
    display: flex;
    align-items: center;
    padding-right: 8px;
  }
}

.name-tags {
  width: 100%;
  color: @heading-color;
  font-weight: 500;
}
