@import '../../../styles/variables.less';
.form-wrapper {
  width: 600px;
}
.invite-container {
  width: 600px;

  .invite-head {
    display: flex;
    flex-direction: column;

    .invite-member {
      font-size: 24px;
      line-height: 36px;
      color: @heading-color;
      font-weight: 500;
    }
  }
  .skip {
    display: flex;
    cursor: pointer;
    img {
      margin-left: 7px;
    }
  }

  .form-wrapper-data {
    .email-input {
      width: 356px;
      margin-right: 16px;
    }
    .role-input {
      width: 228px;
    }
    .add-more-members Button {
      width: 100%;
      height: 38px;
      border: 1px dashed @text-color-secondary;
      color: @text-color-secondary;
    }

    .remove-btn {
      cursor: pointer;
      height: 38px;
      display: flex;
      align-items: center;
      margin-left: 16px;
    }

    .send-invitation {
      padding: 4px 16px;
      height: 38px;
      margin: 20px 0px;
    }
    .invitation-btn {
      width: 154px;
    }
  }
}
