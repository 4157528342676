@import '../../../styles/variables.less';

.list-popover {
  color: @error-color;
}
.profile-popover {
  .gx-user-popover {
    padding: 8px;
    .profile-dropdown-divider {
      margin: 8px 4px;
      min-width: auto;
      width: auto;
    }
    li {
      padding: 0px 4px;
      width: 118px;
      border-radius: 5px;
      line-height: 24px;
    }
  }
  .ant-divider-horizontal {
    margin: 8px 0;
  }
  .ant-avatar > img {
    cursor: pointer;
  }
}

.header-profile-icon {
  cursor: pointer;
}
