@import '../../../styles//variables.less';

.integration {
  width: 80%;
  margin: auto;
  padding: 32px 0 0;
  height: calc(100vh - 56px);

  .title {
    font-size: 16px;
    font-weight: 500;
  }

  .description {
    margin-top: 0px;
    color: @text-color;
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 500;
  }

  .integration-container {
    display: flex;
    flex-wrap: wrap;
    gap: 23px;
  }
  .setting-icon {
    font-size: 18px;
    margin-right: 15px;
  }
  .services-box {
    padding: 16px;
    width: 298px;
    height: 202px;
    .ant-card-body {
      padding: 0px;
    }
    .service-info {
      display: flex;
      justify-content: space-between;
      .service-img {
        padding: 8px;
        height: 64px;
        width: 64px;
      }
    }
    .inner-title {
      font-size: 18px;
      font-weight: 600;
      margin-top: 16px;
      margin-bottom: 0px;
    }
    .inner-desc {
      color: @text-color;
      font-size: 14px;
      font-weight: 400;
      margin: 0px;
    }
  }
}

.int-popup-model {
  .int-popup-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: @text-color;
    .title {
      padding: 0;
      margin-bottom: 0;
      border-bottom: none;
      font-size: 14px;
      font-weight: 400;
    }
    .points {
      color: @text-color;
      font-size: 14px;
      font-weight: 400;
    }
    .ant-form-item-label.inputs {
      color: @text-color;
      font-size: 12px;
      font-weight: 400;
      padding: 0;
    }
    .ant-modal-footer {
      border-top: none;
    }
    .event-input {
      display: flex;
      gap: 25px;
      justify-content: space-between;
      padding: 0px 15px;
      margin: 5px;
    }
    .event-container {
      margin-left: 8px;
    }
    .select-all {
      font-size: 15px;
      margin-bottom: 7px;
      font-weight: 500;
    }
  }
  .integration-popup-footer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}
