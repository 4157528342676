@import '../../styles/variables.less';

.update-preview-mobile {
  width: 240px;
  height: 490px;
  border: 12px solid #121515;
  border-radius: 40px;
  background-color: @background-color;
}

.mobile-update {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 11%;
  top: 32%;
  right: 11%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid @secondary-color;
  background: @white-color;
}

.mobile-preview {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  color: @heading-color;
}

.mobile-content {
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  color: @heading-color;
}

.mobile-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 10px;
  color: @text-color;

  .button-preview {
    display: flex;
    align-items: center;
  }

  .ant-btn-primary {
    width: 60px;
    height: 20px;
  }
  .ant-btn > span {
    font-weight: 500;
    font-size: 10px;
  }
}

.preview {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 16%;
  top: 44%;
  font-weight: 400;
  line-height: 22px;
  color: @heading-color;
}
