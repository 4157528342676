@import '../../../styles/variables.less';
.app-details-inner {
  min-width: 100%;
  margin: 0 auto;
  .app-details {
    width: 100%;
    margin: 0 auto;
  }

  .latest-release-card {
    min-width: 364px;
    height: 128px;
    border: 1px solid @secondary-color;
    border-radius: 8px;
    padding: 16px;

    .ant-card-head {
      padding: 0px;
    }
    .ant-card-body {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 16px 0px;
      a.ant-btn {
        padding-top: 0px;
      }
    }
    .download-btn {
      padding-top: 8px !important;
      display: flex;
      height: 38px;
    }
    .download-span {
      padding-left: 4px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @media (min-width: 1200px) {
        max-width: 130px;
      }
    }
    .releases {
      .total-release {
        span {
          font-size: 20px;
          font-weight: 500;
          color: @heading-color;
        }
      }

      .total-downloads {
        margin: 16px 10px 0px 0px;
        span {
          font-size: 20px;
          font-weight: 500;
          color: @heading-color;
        }
      }
    }
    .ant-card-extra {
      svg {
        min-width: 16px;
        height: 39px;
      }
    }
  }

  .ant-card-small
    > .ant-card-head
    > .ant-card-head-wrapper
    > .ant-card-head-title {
    font-size: 16px;
    padding: 0px;
  }

  .android-release {
    .latest-release-card {
      min-width: 276px;
      background-color: @background-color;
      .android-release {
        min-height: 32px;
      }
      .anticon {
        &.android-color {
          color: @android-icon-color;
        }
        &.ios-color {
          color: @text-color-secondary;
        }
        svg {
          display: inline-block;
          width: 32px;
          height: 32px;
        }
      }
      .releases {
        display: flex;
        justify-content: space-between;
      }
      .total-release {
        margin: 16px 18px 0px 0px;
      }
    }
  }

  .table-row {
    border-radius: 8px;
    .table-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border: 1px solid @secondary-color;
      border-radius: 8px 8px 0px 0px;
      .table-header-left {
        h2 {
          font-weight: 500;
          font-size: 16px;
          margin: 0;
        }
      }
      .table-header-right {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .filter-platform {
          margin-right: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .select-platform {
          .ant-select {
            min-width: 100px;
          }
          .ant-select-item-option {
            padding: 8px 16px;
          }
        }
        .ant-checkbox-wrapper {
          align-items: center;
          .ant-checkbox {
            top: 0;
            .ant-checkbox-inner {
              border-radius: 4px;
            }
          }
        }
      }
      .new-release-btn {
        height: 38px;
      }
    }

    .table {
      width: 100%;
      .table:hover {
        transition: all 1s ease-in;
      }
      .table-row {
        row-gap: 0px;
      }
      .ant-table-container table > thead > tr th {
        background-color: transparent;
        border-bottom: 1px solid @secondary-color;
        border-radius: 0;
      }
      .table-content {
        font-size: 14px;
        font-weight: 500;

        .ant-table {
          border: 1px solid @secondary-color;
          padding: 0px 16px;
          border-radius: 0px;
          .ant-table-thead > tr > th,
          .ant-table-tbody > tr > td {
            padding: 16px 8px;
          }
        }
        .action-col {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .archived {
            border: none;
            border-radius: 8px;
            padding: 6px 8px 6px 8px;
            background-color: @background-color;
            .anticon {
              font-size: 18px;
            }
          }
          .hover-btn {
            margin-right: 16px;
            display: flex;
            .ant-table .ant-table-tbody .ant-btn {
              padding: 0px;
            }
            a.ant-btn {
              line-height: 0px;
            }
            .download-hover-btn:first-child {
              margin-right: 8px;
            }
            .download-hover-btn:nth-child(2) {
              margin-right: 8px;
            }
            .download-hover-btn.ant-btn {
              border: none;
              border-radius: 8px;
              padding: 6px 8px 6px 8px;
              background: @background-color;
            }
            .download-hover-btn {
              height: 32px;
              .anticon {
                font-size: 16px;
              }
            }
            a.download-hover-btn {
              padding-top: 6px !important;
            }
          }
          .tooltip {
            margin-right: 8px;
            width: 26px;
          }
        }
      }
      .app-platform .anticon {
        &.android-color {
          color: @android-icon-color;
        }
        &.ios-color {
          color: @text-color-secondary;
        }
        svg {
          width: 13px;
          height: 13px;
        }
      }
      .tooltip .anticon svg {
        color: #e94a4a;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      .ant-table-thead > tr > th {
        border-bottom: 0px;
      }
      .version .ant-table-thead {
        width: 0px;
      }
      .version .anticon svg {
        min-width: 14px;
        height: 14px;
        margin: 0px 10px;
        color: @text-color;
      }
      .ant-table-tbody > tr > td {
        border-bottom: 1px solid #e8e8e8;
      }
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        width: 0;
      }
      .ant-table-pagination-center {
        justify-content: right;
      }

      .ant-pagination-disabled .ant-pagination-item-link,
      .ant-pagination-disabled:hover .ant-pagination-item-link,
      .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
        color: @text-color;
        border-color: transparent;
      }
      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        border: 1px solid transparent;
      }
      .ant-pagination-item-active {
        background: @background-color;
        border-color: transparent;
      }
      .ant-pagination-item-active a {
        color: @text-color;
      }
      .action {
        width: 350px;
        @media (max-width: 991px) {
          width: 250px;
        }
        .ant-dropdown-menu {
          padding: 8px;
          min-width: 180px;
        }
      }
    }
  }
}

.ellipsis-menu {
  width: 100%;
  .ant-dropdown-menu-item {
    padding: 8px;
    border-radius: 5px;
    .menu-releases {
      img {
        padding: 0 4px;
      }
    }
    &.delete {
      .ant-dropdown-menu-title-content {
        display: flex;
        align-items: center;
      }
    }
  }
  .ellipsis-menu-divider {
    margin: 8px 8px;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  .release-avatar {
    min-width: 28px;
    height: 28px;
  }
  .modal-header-right {
    .ant-tag {
      padding: 4px 12px;
      background: @background-color;
      border: 1px solid transparent;
      font-weight: 500;
      font-size: 14px;
      color: @heading-color;
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.release-note {
  .ant-modal-content {
    .ant-modal-body {
      padding: 16px;
    }
  }
}
.feedback-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding: 24px 16px;
    }
  }
}
.ant-modal-content {
  .ant-modal-header {
    padding: 16px;
    .ant-modal-title {
      font-weight: 600;
      .android-color {
        color: @android-icon-color;
      }
      .ios-color {
        color: @text-color-secondary;
      }
      span {
        font-size: 16px;
        font-weight: 500;
        margin-right: 8px;
      }
      .ant-tag {
        padding: 4px 12px;
        background: @background-color;
        border: 1px solid transparent;
        span {
          font-size: 14px;
          font-weight: 500;
          color: @heading-color;
        }
      }
    }
  }
  .ant-modal-body {
    padding: 0px;
    .title {
      padding: 16px;
      font-size: 14px;
      font-weight: 500;
      border-bottom: 1px solid @secondary-color;
      color: @heading-color;
    }
    .data-count {
      background-color: @background-color;
      border-radius: 100px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      padding: 5px;
    }
    .provisional-content {
      height: 250px;
      overflow: auto;
    }
    .provisional-content::-webkit-scrollbar {
      width: 8px;
    }
    .heading {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    .release-html {
      overflow: auto;
      max-height: 400px;
      .ql-container.ql-snow {
        min-height: 200px;
      }
    }
    .provisional-content::-webkit-scrollbar-track {
      background: @white-color;
    }

    .provisional-content::-webkit-scrollbar-thumb {
      background-color: @secondary-color;
      border-radius: 20px;
      border: 3px solid @white-color;
    }
    .modal-content {
      padding: 16px;
    }

    .release-notes-modal {
      padding: 2px 16px;
      h5 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: @gray-color;
        margin-top: 8px;
      }
    }
    .link {
      max-width: 200px;
      .link-details {
        font-weight: 500;
        .anticon svg {
          display: inline-block;
          color: @gray-color;
          width: 12px;
          height: 12px;
        }
        .link-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: @gray-color;
        }
      }

      .release-link {
        flex: 9;
      }
    }
    .expiry-details {
      .tooltip .anticon svg {
        color: #e94a4a;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
    .action-btn {
      .anticon svg {
        min-width: 18px;
        height: 18px;
      }
    }
    .ant-table-thead > tr > th {
      background: transparent;
      border-bottom: 1px solid transparent;
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      width: 0px;
    }
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid transparent;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .action-col {
    .hover-btn {
      margin-right: 16px;
      display: flex;
      justify-content: center;
    }
    .ant-btn > .anticon {
      margin: 0px;
    }
  }
  .ant-table .ant-table-tbody .anticon {
    font-size: 20px;
    padding: 3px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-btn > .anticon + span,
  .ant-btn > span + .anticon.download-hover-btn {
    margin-left: 0px;
  }
}

.build-qr {
  .document-copy {
    cursor: pointer;
  }
  border-radius: 8px;
  background-color: @white-color;
  .copy-row {
    padding: 0px 10px 10px 10px;
  }
  .copy-id {
    padding-left: 8px;
    font-weight: 400;
    color: @text-color;
    line-height: 29px;
  }
  .build-qr {
    padding: 16px;
  }
  .build-qr-div {
    .build-qr-message {
      font-size: 16px;
    }
    height: 250px;
    width: 243px;
    padding: 16px;
    align-items: center;
    text-align: center;
    display: flex;
  }
}

.build-share-divider {
  .ant-divider-horizontal {
    margin: 5px 0;
    border-top: 1px;
  }
}

.build-qr-content {
  padding-top: 20px;
  padding-left: 26px;
}

.build-version-content {
  width: 50%;
  padding: 16px;
  .build-app-platform {
    margin-right: 10px;
    .anticon {
      font-size: 18px;
      &.android-color {
        color: @android-icon-color;
      }
      &.ios-color {
        color: @text-color-secondary;
      }
    }
  }
  .build-version-row {
    margin-top: 20px;
    .build-version {
      padding: 5px;
      background: @background-color;
      border-radius: 4px;
      margin-right: 10px;
      font-weight: 500;
      color: @heading-color;
    }
    .build-number {
      justify-content: center;
      padding: 5px;
      background: @background-color;
      border-radius: 4px;
      font-weight: 500;
      color: @heading-color;
    }
  }
  .build-share-app-logo {
    width: 40px;
    height: 40px;
  }
  .app-logo-name-row {
    align-items: center;
  }
  .build-share-app-name {
    font-size: 25px;
    font-weight: 500;
    color: @heading-color;

    margin-left: 15px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    padding-left: 2px;
  }
}

.password-protected {
  color: @heading-color;
  margin-right: 10px;
}

.shared-link-modal {
  .ant-modal-body {
    overflow: auto;
  }
  .ant-modal-content .ant-modal-body .modal-content {
    padding: 0;
    .ant-table-thead {
      font-size: 16px;
    }
  }
  .danger {
    color: #f5222d;
  }
}

.share-modal {
  .ant-input-affix-wrapper {
    height: 38px;
    .ant-input-prefix {
      margin-right: 10px;
    }
    .ant-input {
      min-height: 22px;
    }
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0;
    label {
      color: @text-color-secondary;
      line-height: 24px;
    }
  }
  .build-share-content {
    background-color: @background-color;
    width: 50%;
    padding: 24px 56px;
  }
  .share-switch {
    min-width: 32px;
    background-color: @secondary-color;
    .ant-switch-handle {
      top: 1px;
      height: 14px;
      width: 15px;
    }
  }
  .share-switch.ant-switch-checked {
    background-color: @primary-color;
  }
}

.expire-dropdown {
  .rc-virtual-list-holder-inner {
    .ant-select-item {
      border-bottom: 1px solid @secondary-color;
    }
    .ant-select-item-option-content {
      color: @text-color;
      line-height: 22px;
      padding: 16px;
    }
  }
}

.delete-app-modal {
  .cancel-btn {
    width: 96px;
    font-weight: 500;
    background-color: @background-color;
    border: none;
  }
}

.delete-release {
  .modal-content {
    .modal-header {
      .modal-header-right {
        .anticon {
          margin-right: 8px;
          &.android-color {
            color: @android-icon-color;
          }
          &.ios-color {
            color: @text-color-secondary;
          }
        }
      }
    }
  }
}
