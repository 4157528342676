@import '../../../styles/variables.less';

.gx-main-content-wrapper {
  .row-hover-div {
    :hover {
      background-color: @background-color;
    }
  }
  .app-share-content {
    padding: 32px 32px 0;
    .app-share-card {
      width: 600px;
      .add-member-in-team {
        margin-bottom: 0px;
      }
      .add-members {
        margin-bottom: 0px;
      }
      .option-new-member {
        color: @heading-color;
      }
      .share-app-button {
        margin-left: 10px;
        min-width: 83px;
        min-height: 38px;
      }
      .row-hover {
        display: flex;
        align-items: center;
        padding: 8px 0;
      }
      .app-share-list-avatar {
        :hover {
          background-color: none;
        }
      }
      .member-tag {
        display: flex;
        flex-direction: column;
        .user-email {
          width: 100%;
          padding-right: 10px;
          word-break: break-word;
        }
      }
      .tags {
        display: table-caption;
      }
      .remove-col {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .remove-member-text {
        margin-right: 10px;
        margin-top: 15px;
        cursor: pointer;
        font-size: 12px;
        line-height: 18px;
      }
      .ant-card-body {
        padding: 0px;
      }
      .app-share-list {
        max-height: 400px;
        overflow: auto;
        .member-tag-role {
          border-radius: 50px;
        }
      }
      .ant-divider-horizontal {
        margin: 16px 0px;
      }
      .share-text {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 20px;
      }
    }
  }
}
.app-share-add-member {
  padding: 5px 12px;
  cursor: pointer;
}
.app-share-member-row {
  display: flex;
  align-items: center;
  .member-list {
    padding-left: 6px;
  }
}
.name-tags {
  font-weight: 500;
  width: max-content;
}
.app-share-dropdown {
  .tag-bg {
    margin-right: 0px;
    border-radius: 50px;
  }
  .ant-select-item-option-state {
    display: flex;
    align-items: center;
    margin-left: 6px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .add-member-div {
    .add-member-row {
      .add-img-col {
        margin-right: 12px;
      }
    }
  }
}
