@import '../../../styles/variables.less';

.workspace-card {
  display: flex;
  flex-direction: column;
  padding: 0px;
  background-color: @white-color;
  min-height: calc(100vh - 56px);
  .ant-card {
    width: 600px;
  }
  .ant-card-head {
    padding: 0px 16px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
  }
  .ant-form-item-label > label {
    color: @text-color;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
  .ant-input[disabled] {
    color: @text-color;
    background-color: @background-color;
  }
  .ant-form-item-control-input Button {
    margin-left: 2px;
  }
}

.workspace-card-content {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  .ant-card-body {
    padding: 24px 16px 16px;
  }
  .ant-divider-horizontal {
    margin-bottom: 16px;
  }
  .ant-input {
    padding: 8px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }
}

.workspace-upload {
  display: flex;
  align-items: center;
  .ant-upload.ant-upload-select-picture-card {
    width: 52px;
    height: 52px;
    border: 1px solid @secondary-color;
    background-color: @white-color;
  }
  .ant-upload-picture-card-wrapper {
    width: unset;
  }
  .ant-upload-list-picture-card-container {
    margin: 0;
    width: 52px;
    height: 52px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 0;
    border-radius: 10px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info {
    border-radius: 10px;
  }
}

.workspace-heading {
  margin-left: 6px;
  font-weight: 400;
  line-height: 16px;
  color: @text-color;
}

.workspace-owner-content {
  margin-bottom: 8px;
  .ant-col {
    span {
      font-size: 12px;
    }
  }
}
.workspace-container {
  .workspace-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    .name-tags {
      font-weight: 500;
      line-height: 22px;
      color: @heading-color;
    }
  }

  .workspace-col {
    max-height: 135px;
    overflow-y: auto;
  }
  .workspace-col::-webkit-scrollbar {
    width: 8px;
  }

  .workspace-col::-webkit-scrollbar-track {
    background: @white-color;
  }

  .workspace-col::-webkit-scrollbar-thumb {
    background-color: @secondary-color;
    border-radius: 20px;
    border: 3px solid @white-color;
  }
  .save-button {
    font-weight: 500;
    height: 38px;
    width: 96px;
  }
}

.workspace-image {
  padding-right: 8px;
}
.workspace-data {
  display: flex;
  .ant-avatar {
    flex-shrink: 0;
  }
}

.workspace-data-content {
  display: flex;
  flex-direction: column;
  margin: 0px 8px 12px;
}

.member-count {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.workspace-name {
  color: #1a1d40;
}
.workspace-card-content .ant-input {
  font-size: 14px;
  line-height: 22px;
  color: #1a1d40;
}
