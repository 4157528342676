@import '../../../styles/variables.less';
@import '../../../styles/global/variables.less';

.team-avatar.ant-avatar-square {
  border-radius: 4px;
}

.logo {
  width: 170px;
  cursor: pointer;
  padding: 16px;
}
.dropdown-header {
  min-width: unset !important; // need to give important as dropdown min-width inline css varies based on text.
  width: 180px;
  box-shadow: 0px 24px 44px rgba(26, 29, 64, 0.1);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  border: 1px solid @secondary-color;
  border-radius: 8px;
  overflow: hidden;
  .workspace-dropdown {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: auto;
    max-height: 450px;
    .header-menu {
      padding: 8px;
      padding-bottom: 0;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      overflow: auto;
      max-height: 350px;
      &::-webkit-scrollbar-track {
        background: @white-color;
      }
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: @secondary-color;
        border-radius: 20px;
      }
    }
    .team-name-col {
      display: flex;
      align-items: center;
      .team-heading {
        font-weight: 500;
        width: 105px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2;
      }
    }
    .ant-menu-vertical .ant-menu-item {
      padding: 10px 5px;
      line-height: 1;
      height: auto;
      border-radius: 10px;
      margin: 5px 0;
      &:hover {
        background-color: @grey-3;
        color: @text-color;
      }
    }
    .sticky-button {
      position: sticky;
      bottom: 0;
      padding: 10px;
      background-color: @white-color;
      a {
        color: @text-color;
      }
      .create-workspace-content {
        position: relative;
        z-index: 2;
        padding: 5px;
        border-radius: 10px;
        &:hover {
          background-color: #f5f5f5;
        }
      }
      .ant-divider-horizontal {
        margin: 0;
        margin-bottom: 8px;
      }
    }
  }
  .workspace-scroll {
    max-height: 170px;
  }
  .workspace-scroll::-webkit-scrollbar {
    width: 8px;
  }

  .workspace-scroll::-webkit-scrollbar-track {
    background: @white-color;
  }

  .workspace-scroll::-webkit-scrollbar-thumb {
    background-color: @secondary-color;
    border-radius: 20px;
    border: 3px solid @white-color;
  }
  .create-workspace-content {
    .text {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
    }
  }
  .workspace-title.ant-dropdown-menu-item {
    margin-bottom: 0px;
  }
  .ant-dropdown-menu-item {
    padding: 5px;
    margin-bottom: 8px;
    line-height: 24px;
    border-radius: 5px;
  }
  .ant-dropdown-menu-item-divider.menu-divider {
    margin: 0px 4px 8px 4px;
  }
}
.header-divider.ant-divider {
  height: 24px;
  width: 24px;
  border-left: 1px solid @secondary-color;
}

.menu-divider.ant-dropdown-menu-item-divider {
  margin: 0;
  background-color: @secondary-color;
}

.menu-header-divider.ant-dropdown-menu-item-divider {
  margin: 5px 4px;
}

.dropdown-header {
  .ant-dropdown-menu-title-content {
    .ant-avatar {
      margin-right: 4px;
      margin-bottom: 3px;
    }
  }
}

.header-right {
  .team-logo {
    height: 18px;
    border-radius: 4px;
  }
  .ant-btn {
    padding-left: 8px;
  }
  .ant-btn > span {
    text-align: left;
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .workspace-dropdown {
    display: flex;
    align-items: center;
  }
}

.header-left {
  .ant-divider-vertical {
    margin: 0;
    height: 1.5rem;
    border-left: 1px solid @secondary-color;
  }
  .header-left-content {
    width: 80%;
    .ant-menu-title-content {
      font-weight: 500;
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
      padding: 0px 0px 0px 24px;
    }
    .ant-menu-horizontal > .ant-menu-item::after,
    .ant-menu-horizontal > .ant-menu-submenu::after {
      width: 75%;
    }
  }
}

.app-menu {
  .ant-dropdown-menu {
    padding: 12px;
  }
}
