@import '../../../styles/variables.less';

.gx-main-content-wrapper {
  .apps-content {
    width: 80%;
    margin: auto;
    padding: 32px 0px 0;
    .apps-header {
      display: flex;
      justify-content: center;
      .app-list-row {
        width: 100%;
        padding: 0 8px;
        .ant-col {
          h1 {
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            margin: 0px;
          }
        }
        .search-col {
          .ant-input-affix-wrapper > input.ant-input {
            padding: 1px;
          }
          .app-search-input {
            width: 207px;
            border: 1px solid @border-color;
            padding: 8px 12px;
            border-radius: 8px;
          }
          .create-app-btn {
            margin-left: 16px;
            width: 108px;
            height: 38px;
            font-weight: 500;
          }
          .ant-input-affix-wrapper {
            height: 38px;
            .ant-input-prefix {
              margin-right: 10px;
            }
            .ant-input {
              min-height: 22px;
            }
          }
        }
      }
    }

    .app-list {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      overflow: auto;
      max-height: calc(100vh - 165px);
      .ant-row.app-card-row {
        width: 100%;
        .app-list-card {
          cursor: pointer;
          min-width: 300px;
          .card-content-row {
            .card-content-col {
              font-size: 16px;
              font-weight: 500;
            }
          }
          .ant-card-meta-title {
            line-height: 24px;
            margin: 0px;
          }
          .app-list-info {
            span {
              line-height: 16px;
              font-weight: 400;
              display: flex;
            }
          }
          .ant-card-meta-avatar {
            line-height: 40px;
            padding-right: 8px;
          }
        }
      }
      .app-list-info {
        display: flex;
        justify-content: space-between;
        .anticon svg {
          width: 16px;
          height: 16px;
        }
        .app-list-info-icon {
          color: #e94a4a;
        }
      }
      .ant-card-body {
        padding: 18px 16px 16px;
      }
      .app-list-description {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }
    }
  }
}

@media (max-width: 575px) {
  .gx-main-content-wrapper {
    .apps-content {
      width: 100%;
      padding: 0;
      margin: auto;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 820px) {
  .gx-main-content-wrapper {
    .apps-content {
      width: 90%;
      margin: auto;
    }
  }
}

.empty-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .ant-result {
    padding: 16px;
    h1 {
      font-size: 32px;
      font-weight: 600;
    }
  }
  svg {
    width: 100%;
    height: auto;
  }
}

.apps-details-content {
  width: 80%;
  margin: auto;
  padding: 16px 0px;
  @media (max-width: 820px) {
    width: 90%;
  }
}
