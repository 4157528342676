.layout {
  .ant-divider {
    .ant-divider-vertical {
      height: 20em !important;
    }
  }
  & .login-border {
    border-left: 1px solid @secondary-color;
    height: 332px;
    display: flex;
    padding: 0 24px;
  }
}
