@import '../../styles/variables.less';

.maintenance-content-row {
  width: 100%;
  margin-right: 36px;
}

.maintenance-content-preview {
  position: relative;
}
