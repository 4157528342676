#root {
  height: 100vh;
}

.header-notification {
  display: flex;
  align-items: center;

  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
}

.gx-sidebar-content {
  & > .ant-menu {
    & > .ant-menu-item {
      margin: 12px 0;

      & > .menu-icons {
        font-size: 20px;
      }

      & > span {
        font-size: 18px;
      }
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
}

.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}

.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid @border-bottom-color;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid @border-bottom-color;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid @border-bottom-color;
    border-radius: 0;
  }
}

.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: #efefef;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

//CUSTOM STYLE CLASSES START
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

//margin & padding style
.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.m-16 {
  margin: 16px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-18 {
  margin-right: 18px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-25 {
  margin-right: 25px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-7 {
  margin-left: 7px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-16 {
  margin-left: 16px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-18 {
  margin-bottom: 18px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-40 {
  margin-bottom: 40px;
}

.p-0 {
  padding: 0;
}

.pr-0 {
  padding-right: 0;
}

.pr-5 {
  padding-right: 5px;
}

.pr-16 {
  padding-right: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.pt-8 {
  padding-top: 8px;
}

.p-10 {
  padding: 10px;
}

.p-24 {
  padding: 24px;
}
//margin & padding style

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.gap-20 {
  gap: 20px;
}

.flex-1 {
  flex: 1;
}

// flex-box related style
.d-flex {
  display: flex;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}
// flex-box related style

//width classes

.width-percent-5 {
  width: 5%;
}

.width-percent-10 {
  width: 10%;
}

.width-percent-20 {
  width: 20%;
}

.width-percent-25 {
  width: 25%;
}

.width-percent-36 {
  width: 36%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-45 {
  width: 45%;
}

.width-percent-47 {
  width: 47%;
}

.width-percent-48 {
  width: 48%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-55 {
  width: 55%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-70 {
  width: 70%;
}

.width-percent-80 {
  width: 80%;
}

.width-percent-90 {
  width: 90%;
}

.full-width {
  width: 100%;
}

.line-16 {
  line-height: 16px;
}

.line-20 {
  line-height: 20px;
}

.line-22 {
  line-height: 22px;
}

.line-24 {
  line-height: 24px;
}

.line-26 {
  line-height: 26px;
}

.line-30 {
  line-height: 30px;
}

.line-36 {
  line-height: 36px;
}

.line-45 {
  line-height: 45px;
}

.line-49 {
  line-height: 49px;
}

//width classes

// height classes start

.h-16 {
  height: 16px;
}

.h-30 {
  height: 30px;
}

.h-36 {
  height: 36px;
}

.h-38 {
  height: 38px;
}

.h-40 {
  height: 40px;
}

.h-64 {
  height: 64px;
}

.h-130 {
  height: 130px;
}

.h-150 {
  height: 150px;
}

.h-180 {
  height: 180px;
}

.h-182 {
  height: 182px;
}

.h-188 {
  height: 188px;
}

.h-192 {
  height: 192px;
}

.h-156 {
  height: 156px;
}

.h-158 {
  height: 158px;
}

.h-300 {
  height: 300px;
}

.h-75vh {
  height: 70vh;
}

.h-100vh {
  height: 100vh;
}

// height classes end

//cursor
.pointer {
  cursor: pointer;
}
//cursor

//border style
.b-0 {
  border: none;
}

//border-radius
.b-8 {
  border-radius: 8px;
}

.br-50 {
  border-radius: 50px;
}
//border style

.font-xx-large {
  font-size: xx-large;
}

.font-large {
  font-size: large;
}

.font-24 {
  font-size: 24px;
}

.font-20 {
  font-size: 20px;
}

.font-18 {
  font-size: 18px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

//font weight classes

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.word-break-word {
  word-break: break-word;
}
//CUSTOM STYLE CLASSES END

// Placeholder color common

.ant-input::placeholder {
  color: @text-color-secondary;
}

.ant-select-selection-placeholder {
  color: @text-color-secondary;
}

// input and select height common

.ant-input {
  min-height: 38px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 38px;
}

.ant-select-multiple .ant-select-selector {
  min-height: 38px;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: @heading-color;
  background-color: @background-color;
  border-color: @border-settings-color;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: @heading-color;
}
.ant-input-affix-wrapper-disabled {
  background-color: @background-color;
  border-color: @border-settings-color;
}
.ant-input[disabled] {
  color: @heading-color;
  background-color: @background-color;
  border-color: @border-settings-color;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: @background-color;
}

.ant-modal-content {
  overflow: hidden;
}

// invited tag common
.gx-main-content-wrapper {
  .tag-bg {
    width: 48px;
    font-size: 10px;
    height: 18px;
    background: #f9deaf;
    color: #97650d;
    border: none;
    margin-bottom: 4px;
  }

  .tag-bg:hover {
    background-color: #f9deaf;
  }
}

.tag-bg {
  width: 48px;
  font-size: 10px;
  height: 18px;
  background: #f9deaf;
  color: #97650d;
  border: none;
  margin-bottom: 4px;
}

// common button

.save-btn {
  font-weight: 500;
  width: 96px;
  height: 38px;
}

.cancel-btn {
  width: 96px;
  font-weight: 500;
  background-color: @background-color;
  border: none;
}

.delete-btn {
  width: 96px;
  font-weight: 500;
  border: none;
}

// no access screen design

.no-access-height {
  height: calc(100vh - 57px);
  .ant-card-body {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.html-editor {
  .ql-toolbar.ql-snow {
    border-radius: 10px 10px 0 0;
    background-color: #fafafa;
    Button {
      margin: 0;
    }
  }
  .ql-container.ql-snow {
    border-radius: 0 0 10px 10px;
    .ql-editor {
      height: 15em;
      overflow: auto;
    }
  }
}

.router-prompt {
  .ant-modal-content {
    .ant-modal-body {
      padding: 20px;
    }
  }
}

.common-footer {
  &.ant-layout-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: unset;
    .footer-image {
      margin-left: 8px;
    }
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
}
.custom-popconfirm {
  .ant-popover-inner-content {
    width: max-content;
  }
}
