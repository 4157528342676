@import '../../styles/variables.less';

.heading-content {
  font-weight: 500;
  line-height: 18px;
  color: @heading-color;
}

.api-key-content {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid @secondary-color;
}

.row-content {
  font-weight: 400;
  line-height: 24px;
  margin: 16px;
  color: @heading-color;
}

.api-key-copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid @secondary-color;
  background-color: @input-bg;
  width: 96%;
  margin: 0 0 16px 16px;
  background: @background-color;
}

.col-content {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}
