@import '../../../styles/variables.less';
.gx-main-content-wrapper {
  .release-container {
    margin: auto;
    width: 600px;
    padding: 32px 0 0;
    .release-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .release-app-name {
        color: @heading-color;
        font-weight: 500;
        display: flex;
        align-items: center;
      }

      .ant-tag {
        margin-left: 12px;
        border: none;
        padding: 6px 12px;
      }
    }

    .upload-btn {
      width: 138px;
      font-weight: 500;
    }

    .ant-form-item.checkbox {
      margin-bottom: 16px;
      .ant-checkbox-inner {
        height: 18px;
        width: 18px;
        border-radius: 4px;
      }
    }
    .dragger-parent {
      position: relative;

      .ant-upload-list {
        position: absolute;
        left: 62px;
        top: 100px;
        font-size: 14px;
        font-weight: 600;
        width: 200px;
      }
      .ant-upload-list-item {
        margin-top: 0px;
        .close-icon {
          font-size: 12px;
        }
        .anticon-close {
          top: 0px;
        }
      }

      .ant-row .ant-form-item {
        margin-bottom: 0px;
      }
      .content-text {
        line-height: 26px;
      }
    }
    .ant-upload.ant-upload-drag .ant-upload-drag-container {
      display: flex;
      vertical-align: middle;
      flex-direction: column;
    }
    .ant-progress-small.ant-progress-line,
    .ant-progress-small.ant-progress-line .ant-progress-text .anticon {
      margin-top: 25px;
      width: 85%;
    }
    .ant-form-item .dragger-parent .ant-upload.ant-upload-drag {
      width: 292px;
      background: @white-color;
      border: 1px dashed @text-color-secondary;

      .ant-form-item {
        margin-bottom: 0px;
      }
    }
  }
}
