@import '../../../styles/variables.less';

.steps-wrapper {
  width: 80%;
  height: auto;
  margin: 40px auto;
  padding-right: 16px;
  padding-left: 16px;
  .ant-steps {
    /* Set "my-sec-counter" to 0 */
    counter-reset: my-sec-counter;
  }

  .ant-steps-item::before {
    /* Increment "my-sec-counter" by 1 */
    counter-increment: my-sec-counter;
    content: counter(my-sec-counter);
    font-size: 16px;
    position: absolute;
    height: 32px;
    width: 32px;
    margin-left: 46px;
    background: @text-color-secondary;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-steps-item {
    .ant-steps-icon {
      display: none;
    }
  }
  .ant-steps-label-vertical {
    .ant-steps-item-tail {
      padding: 7px 12px;
      margin-left: 66px;
      &::after {
        height: 2px;
      }
    }
  }
  .ant-steps-small {
    .ant-steps-item-icon {
      width: 32px;
      height: 32px;
    }
  }
  .ant-steps-item-finish::before {
    background: @primary-color;
    color: @white-color;
  }
  .ant-steps-item-process::before {
    background: @white-color;
    border: 1px solid @primary-color;
    color: @primary-color;
  }
  .ant-steps-item-wait::before {
    background: @text-color-secondary;
    color: @white-color;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: @text-color;
    font-weight: 500;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: @text-color;
    font-weight: 500;
  }
}

.stepper-content {
  width: 100%;

  .ant-steps-item:first-child {
    .ant-steps-item-title {
      min-width: 155px;
    }
  }
}
