@import '../../styles/variables.less';

.layout {
  & .outline-color {
    color: rgba(0, 0, 0, 0.25);
  }
}

.gx-login-content {
  .ant-input-affix-wrapper > input.ant-input {
    padding-left: 2px;
  }
  & .login-text {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    .login-heading-text {
      font-weight: 500;
      color: @heading-color;
      line-height: 36px;
    }
    .login-description {
      line-height: 24px;
      margin-bottom: 24px;
    }
    .check-mail {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      color: @heading-color;
    }
  }
  .gx-login-form {
    .email-input {
      max-width: 371px;
      background-color: @white-color;
    }
  }
  & .otp-div {
    & .otp-input {
      padding-right: 8px;
      & input {
        outline-color: unset;
        border: 1px solid @secondary-color;
        border-radius: 8px;
        height: 38px;
        min-width: 38px;
      }
      .otp-input-focus {
        outline-color: unset;
        border: 1px solid @text-color;
      }
    }
  }
  & .otp-check {
    margin: 8px 0;
    line-height: 24px;
    .ant-checkbox-inner {
      border-radius: 4px;
      height: 18px;
      width: 18px;
      border: 1px solid @text-color;
    }
  }
  & .otp-btn-div {
    display: flex;
    flex-direction: column;
    & .footer-text {
      line-height: 24px;
      margin: 24px 0;
      & a {
        .resend-otp {
          padding: 0 0 0 5px;
        }
      }
    }
    .otp-btn {
      width: 104px;
    }
  }
  & .create-user-form {
    margin-top: 16px;
    & .user-form {
      margin-bottom: 8px;
    }
    .full-name {
      background-color: @white-color;
    }
    .done-btn {
      width: 104px;
    }
  }
  .send-otp-button {
    width: 124px;
    height: 38px;
    margin: 8px 0px 0px 0px;
  }

  .ant-form-item {
    margin: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .layout.fill-width {
    .login-row {
      padding: 0;
    }
  }
}
