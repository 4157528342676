@import '../../styles/variables.less';

.services-header {
  margin: auto;
  padding: 2% 12% 0%;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  .tabs-header {
    .ant-tabs-tab {
      padding: 7px 16px;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: @heading-color;
      font-weight: 500;
    }
    .ant-tabs-ink-bar {
      background: @heading-color;
    }
    .ant-tabs-tab-btn {
      line-height: 24px;
      transition: none;
      font-weight: 400;
    }
    .ant-tabs-tab:hover {
      color: @heading-color;
    }
  }
}
