@import '../../styles/variables.less';
.gx-main-content-wrapper {
  .app-settings-header {
    padding: 0 12%;
    .app-logo-avatar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        margin: 0;
      }
    }
  }

  .app-settings-form {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 3% 22%;
    border: 1px solid @border-settings-color;
    border-radius: 8px;
    .form-header {
      padding: 16px;
    }
    .form-content {
      margin: 16px;

      .logo-text {
        padding-left: 8px;
      }

      .app-edit-info {
        padding-top: 24px;
        .label-appname {
          margin: 0;

          label {
            color: @text-color;
          }
        }
        .form-input {
          margin-bottom: 24px;
        }
      }
    }
  }

  .form-btn {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid @border-settings-color;
    padding: 16px;

    .ant-btn {
      padding: 0;

      span {
        padding: 6px 24px;
      }
    }
  }
}
