@import '../../../styles/variables.less';
.onboard-container {
  .create-app {
    width: 600px;
    .app-name {
      padding: 8px 16px 8px 16px;
    }
    .create-app-wrapper {
      .next-btn {
        width: 86px;
      }
      .create-app-content {
        margin-bottom: 24px;
        .create-app-text {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          color: @heading-color;
        }
        a {
          color: @text-color;
        }
        .create-app-head {
          padding: 0px 15px;
        }
        .skip {
          line-height: 24px;
        }
      }
    }
    .ant-form-item .dragger-parent .ant-upload.ant-upload-drag {
      width: 292px;
      background: @white-color;
      border: 1px dashed @text-color-secondary;

      .ant-form-item {
        margin-bottom: 0px;
      }
    }

    .ant-form-item.checkbox {
      margin-bottom: 16px;
      .ant-checkbox-inner {
        height: 18px;
        width: 18px;
        border-radius: 4px;
      }
    }

    .dragger-parent {
      position: relative;

      .ant-upload-list {
        position: absolute;
        left: 62px;
        top: 100px;
        font-size: 14px;
        font-weight: 600;
        width: 200px;
      }
      .ant-upload-list-item {
        margin-top: 0px;
        .close-icon {
          font-size: 12px;
        }
        .anticon-close {
          top: 0px;
        }
      }

      .ant-row .ant-form-item {
        margin-bottom: 0px;
      }
      .content-text {
        line-height: 26px;
      }
    }

    .ant-upload.ant-upload-drag .ant-upload-drag-container {
      display: flex;
      vertical-align: middle;
      flex-direction: column;
    }
    .ant-progress-small.ant-progress-line,
    .ant-progress-small.ant-progress-line .ant-progress-text .anticon {
      margin-top: 25px;
      width: 85%;
    }

    .skip {
      cursor: pointer;
      img {
        margin-left: 7px;
      }
    }

    Button.ant-btn-primary[disabled],
    Button.ant-btn-primary[disabled]:hover,
    Button.ant-btn-primary[disabled]:focus,
    Button.ant-btn-primary[disabled]:active {
      color: @white-color;
      border-color: @secondary-color;
      background-color: @text-color-secondary;
      text-shadow: none;
      box-shadow: none;
    }
  }
  .fieldset {
    max-width: 600px;
  }
}
